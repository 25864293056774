import { isIE } from "react-device-detect";
import { IntlProvider } from "react-intl";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Head from "next/head";
// import "../styles/styles.scss"
import * as gtag from "../lib/gtag";
import BrowserNotSupported from "../components/BrowserNotSupported";
import Script from "next/script";

// Import Swiper styles
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export const flattenMessages = (nestedMessages, prefix = "") => {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

const languages = {
  pl: require("../locale/pl.json"),
  en: require("../locale/en.json"),
};

export default function App({ Component, pageProps }) {
  const router = useRouter();
  const { locale, defaultLocale } = router;
  const messages = languages[locale];

  // if (!router.isReady) {
  //   return null
  // }

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  if (isIE) {
    return <BrowserNotSupported />;
  } else {
    return (
      <>
        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        />
        <Script
          id="gtag-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />

        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Head>

        <IntlProvider
          messages={flattenMessages(messages)}
          locale={locale}
          defaultLocale={defaultLocale}
        >
          <Component {...pageProps} />
        </IntlProvider>
      </>
    );
  }
}
